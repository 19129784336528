<template lang="pug">
.record
  v-container
    v-row.mt-10
      v-col(cols="12", md="6")
        h5.normal_title_h5 本季戰績
      v-col(cols="6", sm="3", md="2")
        select.selector(v-model="selectedOppTeam", @change="filterChange")
          option(v-for="item in oppTeams", :key="item", :value="item") {{ item }}
      v-col(cols="6", sm="3", md="2")
        select.selector(v-model="selectedLocation", @change="filterChange")
          option(v-for="item in locationOptions", :key="item", :value="item") {{ item }}
      v-col(cols="6", sm="3", md="2")
        select.selector(v-model="selectedOutcome", @change="filterChange")
          option(v-for="item in outcomeOptions", :key="item", :value="item") {{ item }}
    v-row.table_row
      v-col#sticky-nav.table_col.table.position-relative.mt-5(cols="12")
        table
          thead
            tr.bottom_head
              th.bdr(
                v-for="(title, index) in gameHeaders",
                :key="title",
                :class="{ bdr: index != gameHeaders.length - 1 }"
              ) {{ title }}
          tbody
            tr.hover(
              v-for="game in filteredGames",
              :key="game.game_id",
              style="cursor: pointer",
              @click="gameClick(game.game_id)"
            )
              td.bdl.bdr {{ game.stage }}
              td.bdr {{ game.name }}
              td.bdr {{ game.date }}
              td.bdr
                .opponent_container.d-flex.align-center.justify-center
                  span.ml-1 {{ game.opp_team_name }}
              td.bdr {{ game.is_home ? "主" : "客" }}
              td.bdr(
                :class="[game.is_win ? 'text_secondary' : 'text_light_gray']"
              ) {{ game.is_win ? "勝" : "負" }}
              td {{ game.score }}
</template>

<script>
import { getTeamRecords } from "@/api/league";
import StickyColumn from "@/components/common/Table/StickyColumn";
import { get } from "@/utils/util";

export default {
  name: "LeagueTeamRecords",
  components: {
    StickyColumn,
  },
  data() {
    return {
      selectedOppTeam: "對手",
      oppTeams: [],
      selectedOutcome: "勝/負",
      outcomeOptions: ["勝/負", "勝", "負"],
      selectedLocation: "主/客",
      locationOptions: ["主/客", "主", "客"],

      gameHeaders: ["賽別", "場次", "日期", "對手", "主/客", "勝負", "比分"],
      games: [],
      filteredGames: [],
    };
  },
  computed: {
    leagueId() {
      return Number(this.$route.params.leagueId);
    },
    seasonSegmentId() {
      return Number(this.$route.params.seasonSegmentId);
    },
    teamId() {
      return Number(this.$route.params.teamId);
    },
  },
  watch: {
    seasonSegmentId() {
      this.initData();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      if (this.leagueId < 1 || this.seasonSegmentId < 1 || this.teamId < 1)
        return;
      this.selectedOppTeam = "對手";
      this.oppTeams = [];
      this.selectedOutcome = "勝/負";
      this.selectedLocation = "主/客";
      this.games = [];
      this.filteredGames = [];
      await this.getTeamRecordsApi();
    },
    async getTeamRecordsApi() {
      const params = {
        team_id: this.teamId,
        season_segment_id: this.seasonSegmentId,
      };
      const response = await getTeamRecords(params);
      this.oppTeams = response.data.opp_teams;
      if (this.oppTeams.length > 0) this.selectedOppTeam = this.oppTeams[0];
      this.games = response.data.games;
      this.filteredGames = this.games;
    },
    filterChange() {
      this.filteredGames = this.games;
      if (this.selectedOppTeam !== "對手")
        this.filteredGames = this.filteredGames.filter(
          (game) => game.opp_team_name === this.selectedOppTeam
        );

      if (this.selectedLocation !== "主/客")
        this.filteredGames = this.filteredGames.filter(
          (game) =>
            (this.selectedLocation === "主" && game.is_home) ||
            (this.selectedLocation === "客" && !game.is_home)
        );

      if (this.selectedOutcome !== "勝/負")
        this.filteredGames = this.filteredGames.filter(
          (game) =>
            (this.selectedOutcome === "勝" && game.is_win) ||
            (this.selectedOutcome === "負" && !game.is_win)
        );
    },
    gameClick(gameId) {
      this.$router.push({
        name: "LeagueGame",
        params: {
          leagueId: this.leagueId,
          id: gameId,
        },
      });
    },
    getRankText(ranking) {
      let win_data = get(ranking, "win_rate", {});
      let rank = get(win_data, "ranking", null);
      if (rank == null) {
        return null;
      } else if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";

table {
  text-align: center;
}
</style>
